<script setup lang="ts">
import type { AlgoliaProduct } from '~/types/models/algoliaProduct';

const props = withDefaults(
    defineProps<{
        products?: AlgoliaProduct[] | null;
        hideTitle?: boolean;
        isSingleElement?: boolean;
    }>(),
    {
        products: null,
        hideTitle: true,
        isSingleElement: false,
    },
);

const { getLastSeenProducts, lastSeenProducts, lastSeenProductNumbers } = useAlgoliaProductSlider();
const productsToShow = computed(() => props.products ?? lastSeenProducts.value);
const { getAvailabilities } = useAvailability();

if (props.isSingleElement) {
    useLazyAsyncData('last-seen-products', async () => {
        const products = await getLastSeenProducts();
        const availabilities = await getAvailabilities(lastSeenProductNumbers.value);
        return { products, availabilities };
    });
}
</script>

<template>
    <div
        v-if="productsToShow && productsToShow?.length"
        class="cms-element-last-seen-products-slider"
        data-testid="last-seen-products-slider"
    >
        <h2
            v-if="!hideTitle"
            class="content-heading mb-6 text-center"
        >
            {{ $t('productSliderTabs.tabTitles.last_seen') }}
        </h2>

        <ProductSlider
            :config="
                {
                    navigation: {
                        source: 'static',
                        value: true,
                    },
                } as any
            "
            :products="productsToShow"
            category-type="last_viewed"
        />
    </div>
</template>
